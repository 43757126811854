import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueSweetalert2 from 'vue-sweetalert2';
import '@babel/polyfill';
import moment from 'moment';

// Styles
import 'sweetalert2/dist/sweetalert2.min.css';

// Config
Vue.config.productionTip = false;

// Configure baseURL dynamically based on the domain
if (window.location.hostname === 'squad2.brshop.online') {
  Vue.config.baseURL = 'https://squad2.brshop.online/api/v1';
} else {
  Vue.config.baseURL = 'https://squad.brshop.online/api/v1';
}

// Set axios default base URL for API requests
axios.defaults.baseURL = Vue.config.baseURL;

// Plugins
Vue.use(VueAxios, axios);
Vue.use(VueSweetalert2);
Vue.prototype.moment = moment;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
