<template>
  <v-card class="mt-12">
    <v-card-actions> 404 Page not found! </v-card-actions>

    <v-card-actions>
      <v-btn primary to="/">Go back Dashboard home page</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>
